@import '../../styles/customMediaQueries.css';

.layoutWrapperMain {
  /* min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  } */
}

.root {
  composes: marketplaceModalRootStyles from global;
  composes: defaultBackgroundImageForDesktop from global;
  border: none;
  border-radius: 0;
  position: relative;
  background-image: url('../../assets/auth-images/authbackground.png');

  @media(max-width: 768px) {
    background-image: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    /* border: 1px solid #939393; */
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}

.mobileBackground {
  display: none;

  @media(max-width: 768px) {
    height: 195px;
    display: block;

    & img {
      width: 100%;
    }
  }
}

.loginBox {
  composes: marketplaceLoginModalRootStyles from global;
  composes: defaultBackgroundImageForDesktop from global;
  border: none;
  padding: 130px 0;
  border-radius: 0;
  position: relative;
  background-size: 100% 100%;
  background-image: url('../../assets/auth-images/login.png');

  @media(max-width: 768px) {
    padding: 130px 25px;
    background-size: auto;
    background-position: bottom center;
  }

  &>div {
    border-radius: 10px;

    @media(max-width: 460px) {
      width: 100%;
    }
  }
}

.loginCenterLogo {
  text-align: center;
  margin-top: -100px;

  & img {
    @media(max-width: 460px) {
      width: 129px;
      height: 129px;
    }
  }
}

.content {
  /* width: calc(100% - 48px); */
  margin: 12px 24px 24px 24px;
  /* width: calc(100% - 48px); */
  /* margin: 12px 24px 24px 24px; */
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  z-index: 1;
  width: 733px;
  margin: auto;
  padding: 36px 77px 59px 77px;

  @media (max-width: 768px) {
    padding: 43px 25px;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 20px 24px 43px 24px;
  }
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.signupForm {

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;

  @media (--viewportMedium) {
    flex-basis: 410px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
    height: 80vh;
    overflow-y: scroll;
    padding-right: 20px;
  }
}

.termsHeading {
  composes: h1 from global;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  composes: marketplaceModalCloseStyles from global;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

/* Helper links */
.modalHelperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 5px;
  color: var(--failColor);
  line-height: 20px;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.confirmInfoText {
  composes: h4 from global;
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0;
  }
}

.socialButtonsOrText {
  background-color: var(--matterColorLight);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.authLinksSignup {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #000000;
  margin-bottom: 25px;

  @media(max-width: 500px) {
    margin-top: 24px;
  }

  & a {
    font-weight: 700;
    color: #4661ED;
    margin-left: 5px;
    text-decoration: underline;
  }
}

.authLinksLogin {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #000000;
  margin-top: 21px;
  margin-bottom: 0;

  & a {
    font-weight: 700;
    color: #4661ED;
    margin-left: 5px;
    text-decoration: underline;
  }
}

.memberFreeText {
  @media(max-width: 600px) {
    display: block;
  }
}